import React, { useState } from 'react';
import useHeaderColor from '../../hooks/useHeaderColor'
import { getMenuStyles } from '../../utils/common'
import OutsideClickHandler from 'react-outside-click-handler';
import { BiMenuAltRight } from 'react-icons/bi';
import './Header.css';

const Header = () => {

	const [menuOpened, setMenuOpened] = useState(false);
	const headerColor = useHeaderColor();
	return (
		<section className="h-wrapper" style={{ background: headerColor }}>
			<div className="flexCenter innerWidth paddings h-container">
				{/* logo */}
				<img src="./logo.png" alt="logo" width={100} />

				{/* menu */}
				<OutsideClickHandler
					onOutsideClick={() => {
						setMenuOpened(false);
					}}
				>
					<div
						// ref={menuRef}
						className="flexCenter h-menu"
						style={getMenuStyles(menuOpened)}
					>
						<a href="#residencies">Residencies</a>
						<a href="#value">Our Value</a>
						<a href="#contact-us">Contact Us</a>
						<a href="#get-started">Get Started</a>
						<button className="button">
							<a href="mailto:zainkeepscode@gmail.com">Contact</a>
						</button>
					</div>
				</OutsideClickHandler>

				{/* for medium and small screens */}
				<div
					className="menu-icon"
					onClick={() => setMenuOpened((prev) => !prev)}
				>
					<BiMenuAltRight size={30} />
				</div>
			</div>
		</section>
	);
}

export default Header